import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Hero, Columns, Container } from 'react-bulma-components'

function AnnaQuote() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <hr />
          <Columns className="is-inline-flex">
            <Columns.Column className="is-3 is-offset-1">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120437-anna.jpg?w=300&fit=facearea&facepad=5&mask=ellipse"
                alt="Quote By Anna - Pocketworks"
              />
            </Columns.Column>
            <Columns.Column className="is-6 ">
              <br />
              <br />
              <br />
              <p className="is-size-4">
                "One of the biggest challenges our clients face is discovering
                customer pain points. Once you understand those, it's much
                easier to design a solution that meets their needs."
              </p>
              <p className="tag is-medium">Anna, Digital Product Designer</p>
            </Columns.Column>
          </Columns>
          <hr />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default AnnaQuote
