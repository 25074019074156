import React, { useEffect } from 'react'
import { Columns, Box } from 'react-bulma-components'

function ProcessWidget() {
  useEffect(() => {
    const $cards = document.querySelectorAll('.process-box-card')
    const $image = document.querySelector('.process-box-image')
    let i = 0
    $cards.forEach((el) => {
      if (i === 0) {
        el.classList.add('is-active')
        $image.setAttribute('src', el.getAttribute('data-target'))
        $image.setAttribute(
          'alt',
          'Mobile Strategy services offered by UK app developers'
        )
      }

      el.addEventListener('click', () => {
        $image.setAttribute('src', el.getAttribute('data-target'))
        $image.setAttribute(
          'alt',
          'UK App Development can involve Data and Websites'
        )
        $cards.forEach((el) => {
          el.classList.remove('is-active')
        })
        el.classList.toggle('is-active')
      })

      el.addEventListener('mouseover', () => {
        $image.setAttribute('src', el.getAttribute('data-target'))
        $cards.forEach((el) => {
          el.classList.remove('is-active')
        })
        el.classList.toggle('is-active')
      })
      i += 1
    })
  }, [])

  return (
    <Columns className="is-centered has-text-centered is-vcentered">
      <Columns.Column className="is-3-widescreen is-3-desktop is-size-6 is-5-tablet is-12-mobile">
        <Box
          className="process-box-card card is-flat-link-box has-text-left"
          data-target="https://www.datocms-assets.com/20955/1613378949-process-innovate.png?fm=png&h=400"
        >
          <h3>User-centred design</h3>
          <p>
            Put customers first and see higher organic growth and retention in
            your app.
          </p>

          {/* <p>Nothing is worse than an app that no one uses. Your ideas are almost always adopted if the users test and verify your concept. This is called human-centered design - a core part of our process.
            </p> */}
        </Box>

        <br />

        <Box
          className="process-box-card card is-flat-link-box has-text-left"
          data-target="https://www.datocms-assets.com/20955/1613379172-process-deliver.png?fm=png&h=400"
        >
          <h3>Technology agnostic</h3>
          <p>
            Launch experiences where customers are. Phones, tablets, laptops and
            browsers.
          </p>
        </Box>
      </Columns.Column>

      <Columns.Column className="is-3-widescreen is-3-desktop is-2-tablet">
        <img className="process-box-image" src="" alt="" />
      </Columns.Column>

      <Columns.Column className="is-3-widescreen is-3-desktop is-size-6 is-5-tablet is-12-mobile">
        <Box
          className="process-box-card card is-flat-link-box has-text-left is-5-tablet is-12-mobile"
          data-target="https://www.datocms-assets.com/20955/1613379205-process-dash.png?fm=png&h=400"
        >
          <h3>Data-driven outcomes</h3>
          <p>
            No tech for tech sake. Use data to measure what works and achieve
            better outcomes.
            {/* We're always validating, always challenging and always looking at the data. */}
          </p>
          {/* <p>It's hard to make good decisions if you can't see what's happening. Data will tell you which bits are working, and why. Our process includes creating a data strategy to ensure you have what you need.  </p> */}
        </Box>

        <br />

        <Box
          className="process-box-card card is-flat-link-box has-text-left"
          data-target="https://www.datocms-assets.com/20955/1613379246-process-learn.png?fm=png&h=400"
        >
          <h3>Partners, not suppliers</h3>
          <p>
            Partners protect your interests. They challenge, care and deliver
            more.{' '}
          </p>
          {/* <h3>4.<br/>Epic vision, small steps</h3>
            <p>Too much too soon is a recipe for disaster. Healthy software has a bold vision and is achieved in bite-sized steps with collaboration and feedback.   
              </p> */}
        </Box>
      </Columns.Column>
    </Columns>
  )
}

export default ProcessWidget
