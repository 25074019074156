import React from 'react'
import { Columns, Container, Hero } from 'react-bulma-components'

function Stats() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <Columns className="is-centered">
            <Columns.Column className="is-3 has-text-centered">
              <h2 className="is-size-1 has-text-weight-bold has-text-centered mb-0 is-landing-text">
                <span>3M+</span>
              </h2>
              <p className="has-text-dark is-size-6 is-uppercase">
                App installs per year
              </p>
            </Columns.Column>
            <Columns.Column className="is-3 has-text-centered">
              <h2 className="is-size-1 has-text-weight-bold has-text-centered has-text-success mb-0 is-landing-text">
                <span>24M</span>
              </h2>
              <p className="has-text-dark is-size-6 is-uppercase">
                Yearly mobile transactions
              </p>
            </Columns.Column>
            <Columns.Column className="is-3 has-text-centered">
              <h2 className="is-size-1 has-text-weight-bold has-text-centered has-text-success mb-0 is-landing-text">
                <span>£250M+</span>
              </h2>
              <p className="has-text-dark is-size-6 is-uppercase">
                App-driven revenue
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default Stats
