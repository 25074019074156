import PropTypes from 'prop-types'
import React from 'react'

// Components
import Layout from 'components/layout'
import SiteNav from 'components/navbar'
import Testimonial from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objective from 'components/objectives'
import AnnaQuote from 'components/annaQuote'

// Page Components
import AppUxHero from './components/app-ux-hero'
import Stats from './components/stats'
import DesignServices from './components/design-services'
import DesignThinking from './components/design-thinking'

function ServicesAppUXDesign({ data: { page, testimonials, posts } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <AppUxHero page={page} />
      <Stats />
      <DesignServices />
      <Testimonial testimonials={testimonials} />
      <DesignThinking posts={posts} />
      <Clients />
      <Customers />
      <Objective />
      <AnnaQuote />
    </Layout>
  )
}

ServicesAppUXDesign.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesAppUXDesign
