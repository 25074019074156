import PropTypes from 'prop-types'
import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function AppUxHero({ page }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <div className="columns is-tablet is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1 className="is-size-6-tablet is-size-5-desktop is-size-5-widescreen is-size-6-mobile  mt-5 pb-0 mb-0 has-text-weight-light">
                User Experience Design.
              </h1>
              <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text">
                Take a customer-centric approach to{' '}
                <span className="has-text-primary">app design</span>
              </h2>
              <p className="is-size-4">
                So much more than just making things look good. Our UX design
                process helps you discover customer needs and design accessible
                solutions to meet them.
              </p>
            </div>
            <div className="column is-4">
              <GatsbyImage
                image={page.featuredMedia.gatsbyImageData}
                alt="User Experience Design - Pocketworks"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
AppUxHero.propTypes = {
  page: PropTypes.object,
}
export default AppUxHero
