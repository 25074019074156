import React from 'react'

// Components
import ServicesBlock from 'components/services-block'

function DesignServices() {
  return (
    <ServicesBlock
      title={
        <span>
          <span className="has-text-purple">Design </span> human-friendly
          experiences
        </span>
      }
      subtitle="Conduct user research to gain insight into your customers, then design successful digital products for mobile and web."
      items={[
        {
          title: 'Qualitative Research',
          text: 'Learn what makes your customers or employees tick and find new innovation opportunities.',
          url: '/services/user-research',
        },
        {
          title: 'Quantitative Research',
          text: 'Use analytics and data to design a product that will resonate with your audience.',
          url: '/services/user-research',
        },
        {
          title: 'Digital Ideas',
          text: 'Collaboratively generate new ways to solve a business problem or customer pain point.',
        },
        {
          title: 'Prototypes',
          text: 'Efficiently creat visual prototypes that foster feedback from stakeholders and customers.',
        },
        {
          title: 'User Testing',
          text: 'Streamline your app based on feedback in order to increase conversion rates and retention.',
          url: '/services/user-research',
        },
        {
          title: 'Mobile App Design',
          text: 'We help you design slick, polished mobile apps using the latest mobile standards.',
        },
        {
          title: 'Web Application Design',
          text: 'Design web applications or Progressive Web Apps that are fast, accessible and easy to use. ',
        },
        {
          title: 'Service Design',
          text: 'Design end-to-end customer jouneys spanning mobile, web and bricks and mortar.',
        },
        {
          title: 'Competitor Analysis',
          text: "Analyse your competitors' digital products and find where you can differentiate.",
        },
        {
          title: 'Heuristic Evaluation',
          text: "Have a nagging feeling your app isn't up to scratch? We can review and suggest improvements.",
        },
        {
          title: 'User Interface Design',
          text: "Create polished interfaces that tell customers they're in for a positive brand experience.",
        },
        {
          title: 'Motion Design',
          text: 'Use animation and micro-interactions to help customers have a simpler and more fluid experience.',
        },
      ]}
    />
  )
}

export default DesignServices
