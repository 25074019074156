import React from 'react'
import { Content, Section, Columns } from 'react-bulma-components'

// Components
import ProcessWidget from 'components/process-widget'

function Objectives() {
  return (
    <Section className="mt-3 pt-6 has-background-grey-lighter">
      <Content>
        <Columns className="is-centered pt-6">
          <Columns.Column className="is-6-desktop is-10-tablet is-12-mobile has-text-centered content">
            <h2 className="title">
              What we <span className="">stand for</span>
            </h2>
            <p>
              We're not here to just build "another app". What gets us out of
              bed is making an impact, creating software that people appreciate,
              and helping your organisation get meaningful outcomes.{' '}
            </p>
          </Columns.Column>
        </Columns>
        <div className="mt-5 has-text-centered">
          <ProcessWidget />
        </div>
      </Content>
    </Section>
  )
}

export default Objectives
