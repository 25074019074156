import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Columns, Container, Hero } from 'react-bulma-components'

// Components
import ArrowLink from './link-with-arrow'

function ServicesBlock({ title, subtitle, items }) {
  return (
    <Hero className="pt-6">
      <Hero.Body>
        <Container className="content">
          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <h2 className="is-size-3-mobile title">{title}</h2>
              <p className="is-size-4">{subtitle}</p>
              <br />
            </Columns.Column>
          </Columns>

          <Columns className="is-centered">
            <Columns.Column className="is-10">
              <Columns className="is-multiline is-centered ">
                {items.map((i, index) => (
                  <Columns.Column className="is-4" key={index}>
                    <div className="box card is-equal-height is-link-box">
                      <h5>{i.title}</h5>
                      <p className="">{i.text}</p>
                      {i.url ? (
                        <Link to={i.url} className="is-size-6">
                          {i.urlText ?? 'Read more'}
                        </Link>
                      ) : (
                        <span />
                      )}
                    </div>
                  </Columns.Column>
                ))}
              </Columns>
            </Columns.Column>
          </Columns>

          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <p>
                <br />
                <Link
                  to="/contact"
                  className="button is-primary is-leafy is-medium is-primary"
                >
                  Let's talk
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

ServicesBlock.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.string,
  items: PropTypes.array,
}

export default ServicesBlock
